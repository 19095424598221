/*  . */

.sweet-loading {
  padding-top: 10%;
  padding-left: 47%;
}

.divLoading {
  margin-top: 35%;
  margin-bottom: 50%;
}

.forgot {
  color: #676d61;
  margin-top: -6%;
  font-size: 7px;
  margin-bottom: -2%;
}

.btn-priimary {
  color: #000000;
  background-color: #00ae41;
  border-color: #fff;
}

.btn-priimary:hover {
  background-color: #59cc50;
  color: rgb(8, 8, 8);
}

img.login {
  width: 74%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.backgroundColor {
  background-image: linear-gradient(to top, #295caa 0%, #7acee6 100%);
}

img.LogoplayStore {
  width: 22%;
}

.logo {
  color: #75715e;
  margin-bottom: 10%;
  margin-top: -13%;
}

.logoNST {
  width: 44%;
}

.alignText {
  text-align: center;
  margin-top: 7%;
  margin-bottom: 15%;
}

.colNST {
  position: absolute;
  font-family: sans-serif;
  margin-top: 16px;
  margin-left: -27px;
  text-align: center;
}

.footerCopyright {
  text-align: center;
  color: gray;
  font-size: 10px;
  font-family: inherit;
  font-style: italic;
}

.loader {
  padding-bottom: 20%;
}

.bg-logo {
  background-image: linear-gradient(120deg, #59cc50 0%, #00ae41 100%);
}

.playstoreLogo {
  width: 60%;
  margin-top: 10%;
}

.cardHeader {
  background-color: transparent;
}

.Login {
  width: 100%;
  height: 20px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 35px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: #787a76; 
}

.SignYourAccount {
  color: #43425d;
  font-family: "Courier New", Courier, monospace;
  size: 15.3sp;
  font-weight: bold;
  width: 100%;
  height: 13.5px;
  opacity: 0.89;
  font-size: 15px;
  font-weight: normal;
  line-height: 1.35;
  margin-top: 10px;
}

.inputBox {
  height: 29.5px;
  opacity: 0.78;
  border-radius: 15px;
  border: solid 0.5px #707070;
  background-color: #ffffff;
  outline: none;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.center-block {
  display: table; /* Instead of display:block */
  margin-left: auto;
  margin-right: auto;
}

.getIT {
  width: 70.5px;
  height: 27px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 22.5px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.32;
  letter-spacing: normal;
  text-align: left;
  color: #66a428;
}

b {
  width: 80.5px;
  height: 16.5px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 15.5px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.32;
  letter-spacing: normal;
  text-align: left;
  color: #847f7f;
}

input::placeholder {
  width: 149px;
  height: 11.5px;
  opacity: 0.58;
  font-family: Arial;
  font-size: 12.5px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.14;
  letter-spacing: normal;
  text-align: center;
}

.react-modal {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: #666666;
  opacity: 0.7;
  z-index: 10000;
}

.modal-visible {
  display: block;
}

.modal-hidden {
  display: none;
}

.react-modal .loader-msg {
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  color: #fff;
}
