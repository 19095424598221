.ant-btn {
    color: #c8ced3 !important;
    background-color: transparent !important;
    border-color: #c8ced3  !important;
  }
  
  .ant-btn:hover {
    color: white !important;
    background-color: #f86c6b !important;
    border-color: #f86c6b !important;
  }
  
  .ant-btn-primary {
    color: #00ae41 !important;
    background-color: transparent !important;
    border-color: #59cc50 !important;
  }
  
  .ant-btn-primary:hover {
    color: white !important;
    background-color: #00ae41 !important;
    border-color: #00ae41 !important;
  }
  
  .btn-back {
    color: #00ae41;
    background-color: transparent;
    border-color: #59cc50;
  }
  
  .Btn-Submit {
    margin-left: 10px;
  }
  
  .btn-login {
    background-color: #295caa;
    width: 100%;
    height: 31.5px;
    border-radius: 6px;
    border: solid 0.5px transparent;
    /* color: white; */
  }
  
  .btn-signUp {
    width: 100%;
    height: 31.5px;
    border-radius: 6px;
    border: solid 0.5px #707070;
    background-color: white;
    color: #43425d;
  }
  
  .btn-back:hover {
    color: #00ae41;
    background-color: #c8ced3;
    border-color: #59cc50;
  }
  
  .btn-success {
    color: #00ae41;
    background-color: transparent;
    border-color: #59cc50;
  }
  
  .btn-danger {
    color: #f86c6b !important;
    background-color: transparent !important;
    border-color: #f86c6b !important;
  }
  .btn-danger:hover {
    color: white !important;
    background-color:#f86c6b !important;
    border-color: #f86c6b !important;
  }
  
  .btn-warning {
    color: #ffc107;
    background-color: transparent;
    border-color: #ffc107;
  }
  
  .btn-primary-sm {
    color: #20a8d8;
    background-color: transparent;
    border-color: #20a8d8;
  }
  
  .btn-primary-sm:hover {
    color: white;
    background-color: #20a8d8;
    border-color: #20a8d8;
  }
  
  .btn-login-sm {
    color: White;
    background-color: #ffcb0f;
    border-color: #ffcb0f;
  }
  
  .btn-login-sm:hover {
    color: white;
    background-color: #d3a80c;
    border-color: #d3a80c;
  }
  
  .btn-formOnline {
    color: #43425d;
    background-color: transparent;
    border-color: #d9d9d9;
  }
  
  .btn-formOnline:hover {
    color: white;
    background-color: #20a8d8;
    border-color: #20a8d8;
  }
  .menu {
    background-color: white;
    border-radius: 30px 0px 0px 30px;
  }
  .ant-menu-item-selected {
    background-color:#e4e5e6 !important;
    
  }
  /* .menu-active {
    background-color: #696a67 ;
    border-radius: 30px 0px 0px 30px;
  } */
  .textmenu {
    color:black !important
  }
  .textmenu:hover {
    color:white !important
  }
  .menu:hover {
    background-color: #20a8d8;
    border-radius: 30px 0px 0px 30px;
    
  }
  
  .btn-detail{
    color:#636464 !important;
    background-color: transparent;
    border-color:#636464;
  }
  .btn-detail:hover{
    color: #00ae41 !important;
    background-color: transparent;
  }
  .btn-edit{
    color:#636464 !important;
    background-color: transparent;
    border-color:#636464;
  }
  .btn-edit:hover{
    color:  #ffcb0f !important;
    background-color: transparent;
  }
  .btn-delete{
    color:#636464 ;
    background-color: transparent;
    border-color:#636464;
  }
  .btn-delete:hover{
    color:  #f86c6b !important;
    background-color: transparent;
  }

  .table-responsive {
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
  }
  